<template>
	<v-col>
		<v-layout justify-center class="page-title"
			><strong>工程師登入 Engineer Login</strong></v-layout
		>
		<v-layout justify-center>
			<v-card class="login-form">
				<v-form v-model="valid" ref="form" onSubmit="return false;">
					<v-text-field
						required
						outline
						:rules="userNameRules"
						label="工程師編號 Engineer No."
						type="text"
						v-model="user.engineer_no"
						@keyup.enter="login"
					></v-text-field>
					<v-text-field
						required
						outline
						:rules="passwordRules"
						label="密碼 Password"
						type="password"
						v-model="user.password"
						@keyup.enter="login"
					></v-text-field>
					<v-btn
						class="login-btn justify-center"
						color="blue"
						@click="login"
						dark
						block
						>登入 Login</v-btn
					>
				</v-form>
			</v-card>
		</v-layout>
		<!-- <v-layout justify-center>
                <vue-recaptcha sitekey="/login" :loadRecaptchaScript="true"></vue-recaptcha>
            </v-layout> -->
	</v-col>
</template>

<script>
// import VueRecaptcha from 'vue-recaptcha';
import EnginerService from '../service/engineer-service';
import LoginService from '../service/login-service';

const engineerService = new EnginerService();
const loginService = new LoginService();

export default {
	name: 'PublicLogin',
	components: {
		// VueRecaptcha
	},
	data: () => ({
		valid: false,
		form: null,
		user: {
			engineer_no: '',
			password: '',
		},
		userNameRules: [
			(v) => !!v || '工程師編號必須填寫 Engineer No. is Required',
		],
		passwordRules: [(v) => !!v || '密碼必須填寫 Password is Required'],
	}),
	methods: {
		login() {
			this.$refs.form.validate();
			if (this.valid) {
				let loader = this.$loading.show({
					// Optional parameters
					container: null,
					canCancel: false,
				});
				engineerService
					.login(this.user)
					.then((res) => {
						loader.hide();
						if (res.data.success) {
							// call /me to get info
							engineerService
								.getEngineerInfo()
								.then((info) => {
									loginService.setEngineerType(info.data.result.license_type);

									// go to update password if password is expired
									const toPath = res.data.result.password_expired
										? 'updatepw'
										: 'search';
									this.$router.push(toPath);
								})
								.catch((err) => {
									console.log('error for getEngineerInfo()');
									console.log(err);
									//force the user login again if there is any error
									loginService.logout();
									loader.hide();
									this.promptLoginFailed(
										'系統錯誤，請稍後再試！System Error, please try again!',
									);
								});
						} else {
							this.promptLoginFailed(res.data.result.message);
						}
					})
					.catch(() => {
						loader.hide();
						this.promptLoginFailed(
							'系統錯誤，請稍後再試！System Error, please try again!',
						);
					});
			}
		},
		cacheEngineerNo() {
			if (localStorage) {
				localStorage.setItem('engineer_no', this.user.engineer_no);
			}
		},
		promptLoginFailed(msg) {
			const color = 'error';
			this.$emit('snack-bar', {
				color,
				msg,
			});
		},
	},
	created() {
		//check if logged in already, redirect to submission search
		if (loginService.isLogin()) {
			this.$router.push('search');
		}
	},
};
</script>

<style lang="scss">
.login-title {
	font-size: x-large;
	padding-bottom: 1rem;
}

.login-btn {
	margin-top: 2rem;
	margin-bottom: 1rem;
	/*display: flex;*/
	align-content: center;
}
</style>
