const axios = require('axios')
import jwt_decode from 'jwt-decode';
const loginPath = process.env.VUE_APP_API_DOMAIN + '/engineer/login'
const updatePwPath = (engineer_no) => `${process.env.VUE_APP_API_DOMAIN}/engineer/${engineer_no}/update_password`
const locationSearchPath = (location_id) => `${process.env.VUE_APP_API_DOMAIN}/location/${location_id}`
const engineerInfoPath = process.env.VUE_APP_API_DOMAIN + '/engineer/me'
const refreshTokenPath = process.env.VUE_APP_API_DOMAIN + '/engineer/refresh_token'
import Cookies from 'js-cookie'

export default class EngineerService {
    reqHeader = {
        withCredentials: true
    };

    processToken(res) {
        if (res.data.success) {
            //handle the token, store the payload to cookie
            // Vue.$cookies.config('7d', '', '', true);
            // $cookies.set('token_payload', value[, expireTimes[, path[, domain[, secure[, sameSite]]]]]) //return this

            // console.log(res.data.result.token);
            try {
                jwt_decode(res.data.result.token);
            } catch (err) {
                console.log('invalid token returned');
                throw err;
            }
            // var decoded_payload = JSON.parse(window.atob(res.data.result.token));
            // console.log(Buffer.from(JSON.stringify(decodedPayload)).toString("base64"));
            var encodedPayload = res.data.result.token.split('.')[1];
            // console.log(res.data.result.token.split('.'));
            // console.log('encodedPayload:' + encodedPayload);

            Cookies.set('token_payload', encodedPayload, {
                expires: 1 / 24,
                path: '/'
            })
            // Vue.$cookies.set('token_payload', decoded_payload, 0, null, null, null, 'Strict') //return this

            //tmp use - need to change to cookie later
            // localStorage.engineer_no = this.user.engineer_no;

            // go to update password if password is expired
            // const toPath = res.data.result.password_expired ?
            //     'updatepw' :
            //     'search';
            // this.$router.push(toPath);                    
        }
        return true;
    }

    login(payload) {
        return new Promise((resolve, reject) => {
            axios.post(loginPath, payload, this.reqHeader).then(res => {
                // resolve(res);                
                // console.log(res.data);

                try {
                    this.processToken(res);
                    resolve(res);
                } catch (e) {
                    reject(e);
                }
            }).catch(err => {
                console.log(err);
                reject(err);
            })
        });
    }

    getEngineerInfo() {
        return new Promise((resolve, reject) => {
            //add the Authorization header
            this.reqHeader.headers = {
                "Authorization": Cookies.get('token_payload')
            };
            axios.get(engineerInfoPath, this.reqHeader).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    }

    updatePw(engineer_no, payload) {
        return new Promise((resolve, reject) => {
            //add the Authorization header
            this.reqHeader.headers = {
                "Authorization": Cookies.get('token_payload')
            };
            axios.post(updatePwPath(engineer_no), payload, this.reqHeader).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    }

    searchLocation(location_id) {
        return new Promise((resolve, reject) => {
            //add the Authorization header
            this.reqHeader.headers = {
                "Authorization": Cookies.get('token_payload')
            };
            axios.get(locationSearchPath(encodeURIComponent(location_id)), this.reqHeader).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        });
    }

    refreshToken() {
        return new Promise((resolve, reject) => {
            //add the Authorization header
            this.reqHeader.headers = {
                "Authorization": Cookies.get('token_payload')
            };
            axios.post(refreshTokenPath, null, this.reqHeader).then(res => {
                try {
                    this.processToken(res);
                    resolve(res);
                } catch (e) {
                    reject(e);
                }
            }).catch(err => {
                reject(err);
            })
        });
    }
}